<template>
    <div class="main">
        <div class="progress">
            <el-steps :active="2" align-center>
                <el-step title="注册"></el-step>
                <el-step title="机构认证"></el-step>
                <el-step title="资料审核"></el-step>
                <el-step title="完成"></el-step>
            </el-steps>
        </div>
        <div class="box">
            <div></div>
            <div class="title">机构认证</div>
            <img @click="all('/userEdit')" src="../assets/icon／close@3x.png" alt="" />
        </div>
        <div class="con">
            <mechanism-info> </mechanism-info>
        </div>
    </div>
</template>

<script>
import MechanismInfo from '../components/MechanismInfo.vue';
export default {
    components: { MechanismInfo }
};
</script>

<style lang="less" scoped>
/deep/ .el-steps {
    width: 800px;
    margin: auto;
    margin-top: 30px;
}

/deep/ .el-step__title.is-finish {
    color: #ffffff;
}
/deep/ .el-step__title.is-process {
    color: #cccccc;
    font-weight: 400;
}
/deep/ .el-step__head.is-finish {
    border-color: #ffffff;
    color: #00684f;
}
/deep/.el-step:nth-child(3) {
    .el-step__icon {
        background: #9ec8be !important;
        color: #ffffff;
        border: 0;
    }
}
/deep/.el-step:nth-child(4) {
    .el-step__icon {
        background: #9ec8be !important;
        color: #ffffff;
        border: 0;
    }
}
.main .appContainer {
    width: 100%;
}
.main {
    margin: 0 auto;
    background: #ffffff !important;
    .progress {
        height: 120px;
        background: #00684f;
        margin: -57px 0 50px 0;
    }
    .box {
        width: 900px;
        height: 60px;
        .flex();
        background: linear-gradient(to right, #ffffff 0%, rgba(520, 226, 200, 0.2) 50%, #ffffff 100%);
        justify-content: space-between;
        box-shadow: -2px 0px 0px 0px #ff8700;
        margin: auto;
        .title {
            font-weight: 500;
            color: #ff8700;
            font-size: 16px;
        }
        img {
            width: 24px;
            height: 24px;
            z-index: 1;
        }
    }
    .con {
        margin: 0 auto;
    }
}
</style>
